


import 'babel-polyfill'

//
// Scripts whose functions should be globally available
//

(function (global, factory) {
  typeof exports === 'object' && typeof module !== 'undefined' ? factory(exports) :
  typeof define === 'function' && define.amd ? define(['exports'], factory) :
  (factory((global.WHATWGFetch = {})));
}(this, (function (exports) { 'use strict';

  var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof self !== 'undefined' && self) ||
    (typeof global !== 'undefined' && global);

  var support = {
    searchParams: 'URLSearchParams' in global,
    iterable: 'Symbol' in global && 'iterator' in Symbol,
    blob:
      'FileReader' in global &&
      'Blob' in global &&
      (function() {
        try {
          new Blob();
          return true
        } catch (e) {
          return false
        }
      })(),
    formData: 'FormData' in global,
    arrayBuffer: 'ArrayBuffer' in global
  };

  function isDataView(obj) {
    return obj && DataView.prototype.isPrototypeOf(obj)
  }

  if (support.arrayBuffer) {
    var viewClasses = [
      '[object Int8Array]',
      '[object Uint8Array]',
      '[object Uint8ClampedArray]',
      '[object Int16Array]',
      '[object Uint16Array]',
      '[object Int32Array]',
      '[object Uint32Array]',
      '[object Float32Array]',
      '[object Float64Array]'
    ];

    var isArrayBufferView =
      ArrayBuffer.isView ||
      function(obj) {
        return obj && viewClasses.indexOf(Object.prototype.toString.call(obj)) > -1
      };
  }

  function normalizeName(name) {
    if (typeof name !== 'string') {
      name = String(name);
    }
    if (/[^a-z0-9\-#$%&'*+.^_`|~!]/i.test(name) || name === '') {
      throw new TypeError('Invalid character in header field name')
    }
    return name.toLowerCase()
  }

  function normalizeValue(value) {
    if (typeof value !== 'string') {
      value = String(value);
    }
    return value
  }

  // Build a destructive iterator for the value list
  function iteratorFor(items) {
    var iterator = {
      next: function() {
        var value = items.shift();
        return {done: value === undefined, value: value}
      }
    };

    if (support.iterable) {
      iterator[Symbol.iterator] = function() {
        return iterator
      };
    }

    return iterator
  }

  function Headers(headers) {
    this.map = {};

    if (headers instanceof Headers) {
      headers.forEach(function(value, name) {
        this.append(name, value);
      }, this);
    } else if (Array.isArray(headers)) {
      headers.forEach(function(header) {
        this.append(header[0], header[1]);
      }, this);
    } else if (headers) {
      Object.getOwnPropertyNames(headers).forEach(function(name) {
        this.append(name, headers[name]);
      }, this);
    }
  }

  Headers.prototype.append = function(name, value) {
    name = normalizeName(name);
    value = normalizeValue(value);
    var oldValue = this.map[name];
    this.map[name] = oldValue ? oldValue + ', ' + value : value;
  };

  Headers.prototype['delete'] = function(name) {
    delete this.map[normalizeName(name)];
  };

  Headers.prototype.get = function(name) {
    name = normalizeName(name);
    return this.has(name) ? this.map[name] : null
  };

  Headers.prototype.has = function(name) {
    return this.map.hasOwnProperty(normalizeName(name))
  };

  Headers.prototype.set = function(name, value) {
    this.map[normalizeName(name)] = normalizeValue(value);
  };

  Headers.prototype.forEach = function(callback, thisArg) {
    for (var name in this.map) {
      if (this.map.hasOwnProperty(name)) {
        callback.call(thisArg, this.map[name], name, this);
      }
    }
  };

  Headers.prototype.keys = function() {
    var items = [];
    this.forEach(function(value, name) {
      items.push(name);
    });
    return iteratorFor(items)
  };

  Headers.prototype.values = function() {
    var items = [];
    this.forEach(function(value) {
      items.push(value);
    });
    return iteratorFor(items)
  };

  Headers.prototype.entries = function() {
    var items = [];
    this.forEach(function(value, name) {
      items.push([name, value]);
    });
    return iteratorFor(items)
  };

  if (support.iterable) {
    Headers.prototype[Symbol.iterator] = Headers.prototype.entries;
  }

  function consumed(body) {
    if (body.bodyUsed) {
      return Promise.reject(new TypeError('Already read'))
    }
    body.bodyUsed = true;
  }

  function fileReaderReady(reader) {
    return new Promise(function(resolve, reject) {
      reader.onload = function() {
        resolve(reader.result);
      };
      reader.onerror = function() {
        reject(reader.error);
      };
    })
  }

  function readBlobAsArrayBuffer(blob) {
    var reader = new FileReader();
    var promise = fileReaderReady(reader);
    reader.readAsArrayBuffer(blob);
    return promise
  }

  function readBlobAsText(blob) {
    var reader = new FileReader();
    var promise = fileReaderReady(reader);
    reader.readAsText(blob);
    return promise
  }

  function readArrayBufferAsText(buf) {
    var view = new Uint8Array(buf);
    var chars = new Array(view.length);

    for (var i = 0; i < view.length; i++) {
      chars[i] = String.fromCharCode(view[i]);
    }
    return chars.join('')
  }

  function bufferClone(buf) {
    if (buf.slice) {
      return buf.slice(0)
    } else {
      var view = new Uint8Array(buf.byteLength);
      view.set(new Uint8Array(buf));
      return view.buffer
    }
  }

  function Body() {
    this.bodyUsed = false;

    this._initBody = function(body) {
      /*
        fetch-mock wraps the Response object in an ES6 Proxy to
        provide useful test harness features such as flush. However, on
        ES5 browsers without fetch or Proxy support pollyfills must be used;
        the proxy-pollyfill is unable to proxy an attribute unless it exists
        on the object before the Proxy is created. This change ensures
        Response.bodyUsed exists on the instance, while maintaining the
        semantic of setting Request.bodyUsed in the constructor before
        _initBody is called.
      */
      this.bodyUsed = this.bodyUsed;
      this._bodyInit = body;
      if (!body) {
        this._bodyText = '';
      } else if (typeof body === 'string') {
        this._bodyText = body;
      } else if (support.blob && Blob.prototype.isPrototypeOf(body)) {
        this._bodyBlob = body;
      } else if (support.formData && FormData.prototype.isPrototypeOf(body)) {
        this._bodyFormData = body;
      } else if (support.searchParams && URLSearchParams.prototype.isPrototypeOf(body)) {
        this._bodyText = body.toString();
      } else if (support.arrayBuffer && support.blob && isDataView(body)) {
        this._bodyArrayBuffer = bufferClone(body.buffer);
        // IE 10-11 can't handle a DataView body.
        this._bodyInit = new Blob([this._bodyArrayBuffer]);
      } else if (support.arrayBuffer && (ArrayBuffer.prototype.isPrototypeOf(body) || isArrayBufferView(body))) {
        this._bodyArrayBuffer = bufferClone(body);
      } else {
        this._bodyText = body = Object.prototype.toString.call(body);
      }

      if (!this.headers.get('content-type')) {
        if (typeof body === 'string') {
          this.headers.set('content-type', 'text/plain;charset=UTF-8');
        } else if (this._bodyBlob && this._bodyBlob.type) {
          this.headers.set('content-type', this._bodyBlob.type);
        } else if (support.searchParams && URLSearchParams.prototype.isPrototypeOf(body)) {
          this.headers.set('content-type', 'application/x-www-form-urlencoded;charset=UTF-8');
        }
      }
    };

    if (support.blob) {
      this.blob = function() {
        var rejected = consumed(this);
        if (rejected) {
          return rejected
        }

        if (this._bodyBlob) {
          return Promise.resolve(this._bodyBlob)
        } else if (this._bodyArrayBuffer) {
          return Promise.resolve(new Blob([this._bodyArrayBuffer]))
        } else if (this._bodyFormData) {
          throw new Error('could not read FormData body as blob')
        } else {
          return Promise.resolve(new Blob([this._bodyText]))
        }
      };

      this.arrayBuffer = function() {
        if (this._bodyArrayBuffer) {
          var isConsumed = consumed(this);
          if (isConsumed) {
            return isConsumed
          }
          if (ArrayBuffer.isView(this._bodyArrayBuffer)) {
            return Promise.resolve(
              this._bodyArrayBuffer.buffer.slice(
                this._bodyArrayBuffer.byteOffset,
                this._bodyArrayBuffer.byteOffset + this._bodyArrayBuffer.byteLength
              )
            )
          } else {
            return Promise.resolve(this._bodyArrayBuffer)
          }
        } else {
          return this.blob().then(readBlobAsArrayBuffer)
        }
      };
    }

    this.text = function() {
      var rejected = consumed(this);
      if (rejected) {
        return rejected
      }

      if (this._bodyBlob) {
        return readBlobAsText(this._bodyBlob)
      } else if (this._bodyArrayBuffer) {
        return Promise.resolve(readArrayBufferAsText(this._bodyArrayBuffer))
      } else if (this._bodyFormData) {
        throw new Error('could not read FormData body as text')
      } else {
        return Promise.resolve(this._bodyText)
      }
    };

    if (support.formData) {
      this.formData = function() {
        return this.text().then(decode)
      };
    }

    this.json = function() {
      return this.text().then(JSON.parse)
    };

    return this
  }

  // HTTP methods whose capitalization should be normalized
  var methods = ['DELETE', 'GET', 'HEAD', 'OPTIONS', 'POST', 'PUT'];

  function normalizeMethod(method) {
    var upcased = method.toUpperCase();
    return methods.indexOf(upcased) > -1 ? upcased : method
  }

  function Request(input, options) {
    if (!(this instanceof Request)) {
      throw new TypeError('Please use the "new" operator, this DOM object constructor cannot be called as a function.')
    }

    options = options || {};
    var body = options.body;

    if (input instanceof Request) {
      if (input.bodyUsed) {
        throw new TypeError('Already read')
      }
      this.url = input.url;
      this.credentials = input.credentials;
      if (!options.headers) {
        this.headers = new Headers(input.headers);
      }
      this.method = input.method;
      this.mode = input.mode;
      this.signal = input.signal;
      if (!body && input._bodyInit != null) {
        body = input._bodyInit;
        input.bodyUsed = true;
      }
    } else {
      this.url = String(input);
    }

    this.credentials = options.credentials || this.credentials || 'same-origin';
    if (options.headers || !this.headers) {
      this.headers = new Headers(options.headers);
    }
    this.method = normalizeMethod(options.method || this.method || 'GET');
    this.mode = options.mode || this.mode || null;
    this.signal = options.signal || this.signal;
    this.referrer = null;

    if ((this.method === 'GET' || this.method === 'HEAD') && body) {
      throw new TypeError('Body not allowed for GET or HEAD requests')
    }
    this._initBody(body);

    if (this.method === 'GET' || this.method === 'HEAD') {
      if (options.cache === 'no-store' || options.cache === 'no-cache') {
        // Search for a '_' parameter in the query string
        var reParamSearch = /([?&])_=[^&]*/;
        if (reParamSearch.test(this.url)) {
          // If it already exists then set the value with the current time
          this.url = this.url.replace(reParamSearch, '$1_=' + new Date().getTime());
        } else {
          // Otherwise add a new '_' parameter to the end with the current time
          var reQueryString = /\?/;
          this.url += (reQueryString.test(this.url) ? '&' : '?') + '_=' + new Date().getTime();
        }
      }
    }
  }

  Request.prototype.clone = function() {
    return new Request(this, {body: this._bodyInit})
  };

  function decode(body) {
    var form = new FormData();
    body
      .trim()
      .split('&')
      .forEach(function(bytes) {
        if (bytes) {
          var split = bytes.split('=');
          var name = split.shift().replace(/\+/g, ' ');
          var value = split.join('=').replace(/\+/g, ' ');
          form.append(decodeURIComponent(name), decodeURIComponent(value));
        }
      });
    return form
  }

  function parseHeaders(rawHeaders) {
    var headers = new Headers();
    // Replace instances of \r\n and \n followed by at least one space or horizontal tab with a space
    // https://tools.ietf.org/html/rfc7230#section-3.2
    var preProcessedHeaders = rawHeaders.replace(/\r?\n[\t ]+/g, ' ');
    preProcessedHeaders.split(/\r?\n/).forEach(function(line) {
      var parts = line.split(':');
      var key = parts.shift().trim();
      if (key) {
        var value = parts.join(':').trim();
        headers.append(key, value);
      }
    });
    return headers
  }

  Body.call(Request.prototype);

  function Response(bodyInit, options) {
    if (!(this instanceof Response)) {
      throw new TypeError('Please use the "new" operator, this DOM object constructor cannot be called as a function.')
    }
    if (!options) {
      options = {};
    }

    this.type = 'default';
    this.status = options.status === undefined ? 200 : options.status;
    this.ok = this.status >= 200 && this.status < 300;
    this.statusText = 'statusText' in options ? options.statusText : '';
    this.headers = new Headers(options.headers);
    this.url = options.url || '';
    this._initBody(bodyInit);
  }

  Body.call(Response.prototype);

  Response.prototype.clone = function() {
    return new Response(this._bodyInit, {
      status: this.status,
      statusText: this.statusText,
      headers: new Headers(this.headers),
      url: this.url
    })
  };

  Response.error = function() {
    var response = new Response(null, {status: 0, statusText: ''});
    response.type = 'error';
    return response
  };

  var redirectStatuses = [301, 302, 303, 307, 308];

  Response.redirect = function(url, status) {
    if (redirectStatuses.indexOf(status) === -1) {
      throw new RangeError('Invalid status code')
    }

    return new Response(null, {status: status, headers: {location: url}})
  };

  exports.DOMException = global.DOMException;
  try {
    new exports.DOMException();
  } catch (err) {
    exports.DOMException = function(message, name) {
      this.message = message;
      this.name = name;
      var error = Error(message);
      this.stack = error.stack;
    };
    exports.DOMException.prototype = Object.create(Error.prototype);
    exports.DOMException.prototype.constructor = exports.DOMException;
  }

  function fetch(input, init) {
    return new Promise(function(resolve, reject) {
      var request = new Request(input, init);

      if (request.signal && request.signal.aborted) {
        return reject(new exports.DOMException('Aborted', 'AbortError'))
      }

      var xhr = new XMLHttpRequest();

      function abortXhr() {
        xhr.abort();
      }

      xhr.onload = function() {
        var options = {
          status: xhr.status,
          statusText: xhr.statusText,
          headers: parseHeaders(xhr.getAllResponseHeaders() || '')
        };
        options.url = 'responseURL' in xhr ? xhr.responseURL : options.headers.get('X-Request-URL');
        var body = 'response' in xhr ? xhr.response : xhr.responseText;
        setTimeout(function() {
          resolve(new Response(body, options));
        }, 0);
      };

      xhr.onerror = function() {
        setTimeout(function() {
          reject(new TypeError('Network request failed'));
        }, 0);
      };

      xhr.ontimeout = function() {
        setTimeout(function() {
          reject(new TypeError('Network request failed'));
        }, 0);
      };

      xhr.onabort = function() {
        setTimeout(function() {
          reject(new exports.DOMException('Aborted', 'AbortError'));
        }, 0);
      };

      function fixUrl(url) {
        try {
          return url === '' && global.location.href ? global.location.href : url
        } catch (e) {
          return url
        }
      }

      xhr.open(request.method, fixUrl(request.url), true);

      if (request.credentials === 'include') {
        xhr.withCredentials = true;
      } else if (request.credentials === 'omit') {
        xhr.withCredentials = false;
      }

      if ('responseType' in xhr) {
        if (support.blob) {
          xhr.responseType = 'blob';
        } else if (
          support.arrayBuffer &&
          request.headers.get('Content-Type') &&
          request.headers.get('Content-Type').indexOf('application/octet-stream') !== -1
        ) {
          xhr.responseType = 'arraybuffer';
        }
      }

      if (init && typeof init.headers === 'object' && !(init.headers instanceof Headers)) {
        Object.getOwnPropertyNames(init.headers).forEach(function(name) {
          xhr.setRequestHeader(name, normalizeValue(init.headers[name]));
        });
      } else {
        request.headers.forEach(function(value, name) {
          xhr.setRequestHeader(name, value);
        });
      }

      if (request.signal) {
        request.signal.addEventListener('abort', abortXhr);

        xhr.onreadystatechange = function() {
          // DONE (success or failure)
          if (xhr.readyState === 4) {
            request.signal.removeEventListener('abort', abortXhr);
          }
        };
      }

      xhr.send(typeof request._bodyInit === 'undefined' ? null : request._bodyInit);
    })
  }

  fetch.polyfill = true;

  if (!global.fetch) {
    global.fetch = fetch;
    global.Headers = Headers;
    global.Request = Request;
    global.Response = Response;
  }

  exports.Headers = Headers;
  exports.Request = Request;
  exports.Response = Response;
  exports.fetch = fetch;

  Object.defineProperty(exports, '__esModule', { value: true });

})));


const bodyEl = document.getElementsByTagName('body')[0]

let mobileOverride = false

try {
  const urlParams = new URLSearchParams(window.location.search)
  if (urlParams.has('m')) {
    mobileOverride = true
  }
} catch (err) {
  //
}

function isMobile () {
  return mobileOverride || (navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/gi) !== null)
}

function isIPhone () {
  return navigator.userAgent.match(/(iPhone)|(iPod)/gi) !== null
}

// https://davidwalsh.name/javascript-debounce-function
function debounce(func, wait, immediate) {
	var timeout;
	return function() {
		var context = this, args = arguments;
		var later = function() {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
};

// https://jaketrent.com/post/addremove-classes-raw-javascript

function hasClass(el, className) {
  if (el.classList) {
    return el.classList.contains(className)
  } else {
    return !!el.className.match(new RegExp('(\\s|^)' + className + '(\\s|$)'))
  }
}

function addClass(el, className) {
  if (el.classList) {
    el.classList.add(className)
  } else if (!hasClass(el, className)) {
    el.className += " " + className
  }
}

function removeClass(el, className) {
  if (el.classList) {
    el.classList.remove(className)
  } else if (hasClass(el, className)) {
    var reg = new RegExp('(\\s|^)' + className + '(\\s|$)')
    el.className=el.className.replace(reg, ' ')
  }
}

// https://stackoverflow.com/questions/11068240/what-is-the-most-efficient-way-to-parse-a-css-color-in-javascript
function parseColor (input) {
  if (!input) {
    return null
  }
  
  let m
  m = input.match(/^#([0-9a-f]{3})$/i)
  if (m && m[1]) {
    return [
      parseInt(m[1].charAt(0), 16) * 0x11,
      parseInt(m[1].charAt(1), 16) * 0x11,
      parseInt(m[1].charAt(2), 16) * 0x11,
      255
    ]
  }

  m = input.match(/^#([0-9a-f]{4})$/i)
  if (m && m[1]) {
    return [
      parseInt(m[1].charAt(0), 16) * 0x11,
      parseInt(m[1].charAt(1), 16) * 0x11,
      parseInt(m[1].charAt(2), 16) * 0x11,
      parseInt(m[1].charAt(3), 16) * 0x11,
    ]
  }

  m = input.match(/^#([0-9a-f]{6})$/i)
  if (m && m[1]) {
    return [
      parseInt(m[1].substr(0, 2), 16),
      parseInt(m[1].substr(2, 2), 16),
      parseInt(m[1].substr(4, 2), 16),
      255
    ]
  }

  m = input.match(/^#([0-9a-f]{8})$/i)
  if (m && m[1]) {
    return [
      parseInt(m[1].substr(0, 2), 16),
      parseInt(m[1].substr(2, 2), 16),
      parseInt(m[1].substr(4, 2), 16),
      parseInt(m[1].substr(6, 2), 16),
    ]
  }

  m = input.match(/^rgb\s*\(\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*\)$/i)
  if (m) {
    return [parseInt(m[1]), parseInt(m[2]), parseInt(m[3]), 255]
  }

  m = input.match(/^rgba\s*\(\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*,\s*((\d|\.)+)\s*\)$/i)
  if (m) {
    return [parseInt(m[1]), parseInt(m[2]), parseInt(m[3]), Math.floor(parseFloat(m[4]) * 255.0)]
  }
}

function lerpColor (out, x, a, b) {
  const y = 1.0 - x
  out[0] = a[0] * y + b[0] * x
  out[1] = a[1] * y + b[1] * x
  out[2] = a[2] * y + b[2] * x
  out[3] = a[3] * y + b[3] * x
}


//
// Scripts that should be self-contained and not pollute the global namespace
//

(function(){ if (!bodyEl.dataset.hasContact) {
  return
}

function onContactSuccess () {
  window.alert('Thank you for contacting Phillip Allen Shaw!')

  try {
    window.location.href = '/'
  } catch (err) {
    // Do nothing - we already told the user that sign-up succeeded.
  }
}

function onContactFailed () {
  window.alert('An error occurred. Please ensure you have correctly filled out all fields and are connected to the network.')
}

async function sendContact () {
  const form = {
    email: document.getElementById('email').value,
    firstName: document.getElementById('first-name').value,
    lastName: document.getElementById('last-name').value,
    message: document.getElementById('message').value,
  }

  const request = await window.fetch('/api/contact', {
    method: 'POST',

    headers: {
      'Content-Type': 'application/json',
    },

    body: JSON.stringify({ form })
  })

  if (request.ok) {
    onContactSuccess()
  } else {
    onContactFailed()
  }
}

document.getElementById('submit').addEventListener('click', sendContact)
 })();
(function(){ if (!isMobile()) {
  const stylesheet = document.getElementById('mobile-stylesheet')
  if (stylesheet) {
    stylesheet.sheet.disabled = true
  }

  const inlineStyles = document.getElementsByClassName('mobile-inline-styles')
  for (const style of inlineStyles) {
    style.sheet.disabled = true
  }
}
 })();
(function(){ if (!bodyEl.dataset.hasIosFontSizeHack) {
  return
}

if (isIPhone()) {
  const styleEl = document.createElement('style')
  styleEl.innerHTML = '@media (orientation: landscape) { .subs-choice-details { font-size: 0.75vw !important; } }'
  document.body.appendChild(styleEl)
}
 })();
(function(){ const mainNav = document.getElementById('main-nav')
const navs = document.getElementsByTagName('nav')

//
// Hamburger
//

document.getElementsByClassName('hamburger')[0].addEventListener('click', () => addClass(mainNav, 'hamburger-show'))
document.getElementsByClassName('hamburger-close')[0].addEventListener('click', () => removeClass(mainNav, 'hamburger-show'))

//
// Change navbar text on vertical
//

const hasDataMobileText = []
const hasDataMobileHref = []
for (const nav of navs) {
  for (const el of nav.getElementsByTagName('a')) {
    if (el.dataset.mobileText) {
      el.dataset.origText = el.textContent
      hasDataMobileText.push(el)
    }

    if (el.dataset.mobileHref) {
      el.dataset.origHref = el.href
      hasDataMobileHref.push(el)
    }
  }
}

let prevOrientation = null
function resizeChangeNavbarText (ignorePrevOrientation) {
  if (window.innerWidth >= window.innerHeight) {
    if (prevOrientation === 'v' || ignorePrevOrientation) {
      for (const el of hasDataMobileText) {
        el.textContent = el.dataset.origText
      }

      for (const el of hasDataMobileHref) {
        el.href = el.dataset.origHref
      }

      prevOrientation = 'h'
    }
  } else {
    if (prevOrientation === 'h' || ignorePrevOrientation) {
      for (const el of hasDataMobileText) {
        el.textContent = el.dataset.mobileText
      }

      for (const el of hasDataMobileHref) {
        el.href = el.dataset.mobileHref
      }

      prevOrientation = 'v'
    }
  }
}

if (isMobile()) {
  resizeChangeNavbarText(true) // Initial call since resize not triggered on load
  window.addEventListener('resize', resizeChangeNavbarText)
}
 })();
(function(){ if (!bodyEl.dataset.hasPdfsGrid) {
  return
}

const modalOverlay = document.getElementById('pdf-modal-overlay')
const modalClose = document.getElementById('pdf-modal-close')
const modalPages = document.getElementById('pdf-modal-pages')

function renderPages (url) {
  while (modalPages.firstChild) {
    modalPages.removeChild(modalPages.lastChild)
  }
  
  pdfjsLib.getDocument(url).promise.then((pdf) => {
    for (let i = 1; i <= pdf.numPages; ++i) {
      pdf.getPage(i).then((page) => {
        const viewport = page.getViewport({ scale: 2.0 })

        const canvas = document.createElement('canvas')
        canvas.width = viewport.width
        canvas.height = viewport.height
        canvas.addEventListener('contextmenu', (event) => {
          event.preventDefault()
        })

        const ctx = canvas.getContext('2d')

        page.render({
          viewport,
          canvasContext: ctx
        })

        modalPages.appendChild(canvas)

        if (i === 1) {
          modalOverlay.style.display = 'flex'
        }
      })
    }
  })
}

const pdfButtons = document.getElementsByClassName('pdf-item')
for (let i = 0; i < pdfButtons.length; ++i) {
  const pdfButton = pdfButtons[i]

  pdfButton.addEventListener('click', () => {
    renderPages(pdfButton.dataset.pdfUrl)
    curDownloadSlug = pdfButton.dataset.pdfSlug // See below for definition of curDownloadSlug
  }, false)
}

modalClose.addEventListener('click', () => {
  modalOverlay.style.display = 'none'
}, false)

//
// Download & Auth
//

const pdfsList = document.getElementsByClassName('pdfs-list')[0]
const modalBuy = document.getElementById('pdf-modal-buy')

let curDownloadSlug = null

function onDownloadSuccess (blob, filename) {
  const a = document.createElement('a')
  a.href = window.URL.createObjectURL(blob)
  a.download = filename
  a.style.display = 'none'
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
}

function onDownloadFailed () {
  try {
    window.location.href = '/members'
  } catch (err) {
    window.alert('Please sign in before downloading documents, or subscribe if you do not already have an account. Use the "Members" tab in the top-left to sign in, and the "Sign Up" tab in the top-right to subscribe.')
  }
}

async function download () {
  if (!curDownloadSlug) {
    return
  }

  let slug = (' ' + curDownloadSlug).slice(1) // Force deep copy; in case download takes a while and user switches to another document which makes curDownloadSlug point to a different document instead

  const request = await window.fetch(`/api/download/${pdfsList.dataset.permalink}/${slug}`, {
    credentials: 'include',
  })

  if (request.ok) {
    const blob = await request.blob()
    onDownloadSuccess(blob, `${slug}.pdf`)
  } else {
    onDownloadFailed()
  }
}

modalBuy.addEventListener('click', download)
 })();
(function(){ if (!bodyEl.dataset.hasOnePdf) {
  return
}

const modalOverlay = document.getElementById('pdf-modal-overlay')
const modalClose = document.getElementById('pdf-modal-close')
const modalPagesDiv = document.getElementById('pdf-modal-pages')

function openModal () {
  if (!isMobile()) {
    return
  }

  if (window.innerWidth >= window.innerHeight) {
    renderPages(pagesDiv.dataset.pdfUrl, modalPagesDiv, parseColor(pagesDiv.dataset.background), parseColor(pagesDiv.dataset.foreground), true)
  } else {
    if (mobilePagesDiv) {
      renderPages(mobilePagesDiv.dataset.pdfUrl, modalPagesDiv, parseColor(pagesDiv.dataset.background), parseColor(pagesDiv.dataset.foreground), true)
    } else {
      renderPages(pagesDiv.dataset.pdfUrl, modalPagesDiv, parseColor(pagesDiv.dataset.background), parseColor(pagesDiv.dataset.foreground), true)
    }
  }
}

modalClose.addEventListener('click', () => {
  modalOverlay.style.display = 'none'
}, false)

///

let pagesDiv = null
let mobilePagesDiv = null
for (const el of document.getElementsByClassName('page-pdf')) {
  if (el.dataset.isMobile) {
    mobilePagesDiv = el
  } else {
    pagesDiv = el
  }
}

function renderPages (url, container, bgColor, fgColor, isModal) {
  if (isModal) {
    while (container.firstChild) {
      container.removeChild(container.lastChild)
    }
  }

  pdfjsLib.getDocument(url).promise.then((pdf) => {
    for (let i = 1; i <= pdf.numPages; ++i) {
      pdf.getPage(i).then((page) => {
        const viewport = page.getViewport({ scale: 3.0 })

        const canvas = document.createElement('canvas')
        canvas.width = viewport.width
        canvas.height = viewport.height
        canvas.addEventListener('contextmenu', (event) => {
          event.preventDefault()
        })

        const ctx = canvas.getContext('2d')

        page.render({
          viewport,
          canvasContext: ctx
        }).promise.then(() => {
          const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height)
          const lerped = new Array(4)
          const inv255 = 1.0 / 255.0
          let x = 0, y = 0
          for (y = 0; y < imageData.height; ++y) {
            for (x = 0; x < imageData.width; ++x) {
              // Assume a grayscale document so only pay attention to the red channel of input to speed up
              const baseIdx = (y * imageData.width + x) * 4
              const alpha = 1.0 - imageData.data[baseIdx] * inv255
              lerpColor(lerped, alpha, bgColor, fgColor)
              imageData.data[baseIdx] = lerped[0]
              imageData.data[baseIdx + 1] = lerped[1]
              imageData.data[baseIdx + 2] = lerped[2]
              imageData.data[baseIdx + 3] = lerped[3]
            }
          }
          ctx.putImageData(imageData, 0, 0)

          if (!isModal) {
            canvas.addEventListener('click', openModal)
          }

          if (isModal && i === 1) {
            modalOverlay.style.display = 'flex'
          }

          container.appendChild(canvas)
        })
      })
    }
  })
}

if (pagesDiv) {
  renderPages(pagesDiv.dataset.pdfUrl, pagesDiv, parseColor(pagesDiv.dataset.background), parseColor(pagesDiv.dataset.foreground), false)
}

if (mobilePagesDiv) {
  renderPages(mobilePagesDiv.dataset.pdfUrl, mobilePagesDiv, parseColor(pagesDiv.dataset.background), parseColor(pagesDiv.dataset.foreground), false)
}

let prevOrientation = null
function onResize (ignorePrevOrientation) {
  if (window.innerWidth >= window.innerHeight) {
    if (prevOrientation === 'v' || ignorePrevOrientation) {
      mobilePagesDiv.style.display = 'none'
      pagesDiv.style.display = 'flex'

      prevOrientation = 'h'
    }
  } else {
    if (prevOrientation === 'h' || ignorePrevOrientation) {
      pagesDiv.style.display = 'none'
      mobilePagesDiv.style.display = 'flex'

      prevOrientation = 'v'
    }
  }
}

if (mobilePagesDiv) {
  onResize(true) // Initial call since resize not triggered on load
  window.addEventListener('resize', onResize)
} else {
  pagesDiv.style.display = 'flex'
}
 })();
(function(){ if (!bodyEl.dataset.hasBlogPdfPreviews) {
  return
}

function renderFirstPage (url, container, bgColor, fgColor) {
  pdfjsLib.getDocument(url).promise.then((pdf) => {
    pdf.getPage(1).then((page) => {
      const viewport = page.getViewport({ scale: 1.0 })

      const canvas = document.createElement('canvas')
      canvas.width = viewport.width
      canvas.height = viewport.height
      canvas.addEventListener('contextmenu', (event) => {
        event.preventDefault()
      })

      const ctx = canvas.getContext('2d')

      page.render({
        viewport,
        canvasContext: ctx
      }).promise.then(() => {
        if (bgColor && fgColor) {
          const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height)
          const lerped = new Array(4)
          const inv255 = 1.0 / 255.0
          let x = 0, y = 0
          for (y = 0; y < imageData.height; ++y) {
            for (x = 0; x < imageData.width; ++x) {
              // Assume a grayscale document so only pay attention to the red channel of input to speed up
              const baseIdx = (y * imageData.width + x) * 4
              const alpha = 1.0 - imageData.data[baseIdx] * inv255
              lerpColor(lerped, alpha, bgColor, fgColor)
              imageData.data[baseIdx] = lerped[0]
              imageData.data[baseIdx + 1] = lerped[1]
              imageData.data[baseIdx + 2] = lerped[2]
              imageData.data[baseIdx + 3] = lerped[3]
            }
          }
          ctx.putImageData(imageData, 0, 0)
        }

        container.appendChild(canvas)
      })
    })
  })
}

const previewContainers = document.getElementsByClassName('post-needs-pdf-preview')
for (const el of previewContainers) {
  renderFirstPage(el.dataset.pdfUrl, el, parseColor(el.dataset.pdfBackground), parseColor(el.dataset.pdfForeground))
}
 })();
(function(){ if (!bodyEl.dataset.hasSymphonyPdfs) {
  return
}

const modalOverlay = document.getElementById('pdf-modal-overlay')
const modalClose = document.getElementById('pdf-modal-close')
const modalPages = document.getElementById('pdf-modal-pages')

function renderPages (url, bgColor, fgColor) {
  while (modalPages.firstChild) {
    modalPages.removeChild(modalPages.lastChild)
  }
  
  pdfjsLib.getDocument(url).promise.then((pdf) => {
    for (let i = 1; i <= pdf.numPages; ++i) {
      pdf.getPage(i).then((page) => {
        // Scale could probably be 2.0 if not doing the funky background thing
        const viewport = page.getViewport({ scale: 3.0 })

        const canvas = document.createElement('canvas')
        canvas.width = viewport.width
        canvas.height = viewport.height
        canvas.addEventListener('contextmenu', (event) => {
          event.preventDefault()
        })

        const ctx = canvas.getContext('2d')

        page.render({
          viewport,
          canvasContext: ctx
        }).promise.then(() => {
          const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height)
          const lerped = new Array(4)
          const inv255 = 1.0 / 255.0
          let i = 0, j = 0
          for (j = 0; j < imageData.height; ++j) {
            for (i = 0; i < imageData.width; ++i) {
              // Assume a grayscale document so only pay attention to the red channel of input to speed up
              const baseIdx = (j * imageData.width + i) * 4
              const alpha = 1.0 - imageData.data[baseIdx] * inv255
              lerpColor(lerped, alpha, bgColor, fgColor)
              imageData.data[baseIdx] = lerped[0]
              imageData.data[baseIdx + 1] = lerped[1]
              imageData.data[baseIdx + 2] = lerped[2]
              imageData.data[baseIdx + 3] = lerped[3]
            }
          }
          ctx.putImageData(imageData, 0, 0)

          modalPages.appendChild(canvas)

          if (i === 1) {
            modalOverlay.style.display = 'flex'
          }
        })
      })
    }
  })
}

const postItemButtons = document.getElementsByClassName('post-item-opens-pdf-modal')
for (let i = 0; i < postItemButtons.length; ++i) {
  const postItemButton = postItemButtons[i]

  postItemButton.addEventListener('click', () => {
    modalPages.style.background = `url(${postItemButton.dataset.backgroundImage}) no-repeat center center fixed`
    modalPages.style['background-size'] = 'cover'
    renderPages(postItemButton.dataset.pdfUrl, parseColor(postItemButton.dataset.pdfBackground), parseColor(postItemButton.dataset.pdfForeground))
  }, false)
}

modalClose.addEventListener('click', () => {
  modalOverlay.style.display = 'none'
}, false)
 })();
(function(){ if (!bodyEl.dataset.hasBlogPdfPage) {
  return
}

let pagesDiv = document.getElementsByClassName('page-pdf')[0]

function renderPages (url, container, bgColor, fgColor) {
  pdfjsLib.getDocument(url).promise.then((pdf) => {
    for (let i = 1; i <= pdf.numPages; ++i) {
      pdf.getPage(i).then((page) => {
        const viewport = page.getViewport({ scale: 3.0 })

        const canvas = document.createElement('canvas')
        canvas.width = viewport.width
        canvas.height = viewport.height
        canvas.addEventListener('contextmenu', (event) => {
          event.preventDefault()
        })

        const ctx = canvas.getContext('2d')

        page.render({
          viewport,
          canvasContext: ctx
        }).promise.then(() => {
          if (bgColor && fgColor) {
            const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height)
            const lerped = new Array(4)
            const inv255 = 1.0 / 255.0
            let x = 0, y = 0
            for (y = 0; y < imageData.height; ++y) {
              for (x = 0; x < imageData.width; ++x) {
                // Assume a grayscale document so only pay attention to the red channel of input to speed up
                const baseIdx = (y * imageData.width + x) * 4
                const alpha = 1.0 - imageData.data[baseIdx] * inv255
                lerpColor(lerped, alpha, bgColor, fgColor)
                imageData.data[baseIdx] = lerped[0]
                imageData.data[baseIdx + 1] = lerped[1]
                imageData.data[baseIdx + 2] = lerped[2]
                imageData.data[baseIdx + 3] = lerped[3]
              }
            }
            ctx.putImageData(imageData, 0, 0)
          }

          container.appendChild(canvas)
        })
      })
    }
  })
}

renderPages(pagesDiv.dataset.pdfUrl, pagesDiv, parseColor(pagesDiv.dataset.background), parseColor(pagesDiv.dataset.foreground))
 })();
(function(){ if (!bodyEl.dataset.hasSketchesGrid) {
  return
}

//
// Disabled because sketches looked blurry when zoomed in
//

/*
const modalOverlay = document.getElementById('pdf-modal-overlay')
const modalClose = document.getElementById('pdf-modal-close')
const modalPages = document.getElementById('pdf-modal-pages')

function renderPages (url) {
  while (modalPages.firstChild) {
    modalPages.removeChild(modalPages.lastChild)
  }

  const img = document.createElement('img')
  img.src = url
  img.addEventListener('contextmenu', (event) => {
    event.preventDefault()
  })

  modalPages.appendChild(img)

  modalOverlay.style.display = 'flex'
}

const pdfButtons = document.getElementsByClassName('pdf-item')
for (let i = 0; i < pdfButtons.length; ++i) {
  const pdfButton = pdfButtons[i]

  pdfButton.addEventListener('click', () => {
    renderPages(pdfButton.dataset.imageUrl)
  }, false)
}

modalClose.addEventListener('click', () => {
  modalOverlay.style.display = 'none'
}, false)
*/
 })();
(function(){ if (!bodyEl.dataset.hasPodcasts) {
  return
}

const urlParams = new URLSearchParams(window.location.search)
const filterShowId = urlParams.get('showId')
if (typeof filterShowId !== 'string') {
  return
}

const els = document.getElementsByClassName('podcast-iframe-wrap')
for (let i = 0; i < els.length; ++i) {
  if (els[i].dataset.showId.toLowerCase() !== filterShowId.toLowerCase()) {
    els[i].parentNode.removeChild(els[i])
    --i
  }
}
 })();
(function(){ if (!bodyEl.dataset.hasSignIn) {
  return
}

const emailEl = document.getElementById('email')
const passwordEl = document.getElementById('password')

function onSignInSuccess () {
  try {
    window.location.href = '/'
  } catch (err) {
    window.alert('Sign-in successful! Enjoy your unlimited downloads!')
  }
}

function onSignInFailed () {
  window.alert('Error signing in. Please make sure you correctly entered your email address and password.')
}

async function signIn () {
  const email = emailEl.value
  const password = passwordEl.value

  if (email.length === 0 || password.length === 0) {
    window.alert('Please fill in your email and password.')
    return
  }

  const user = { email, password }

  const request = await window.fetch('/api/user/login', {
    method: 'POST',

    credentials: 'include',

    headers: {
      'Content-Type': 'application/json',
    },

    body: JSON.stringify({ user })
  })

  if (request.ok) {
    onSignInSuccess()
  } else {
    onSignInFailed()
  }
}

document.getElementById('login-button').addEventListener('click', signIn)
 })();
(function(){ if (!bodyEl.dataset.hasResetPasswordSendEmail) {
  return
}

const emailEl = document.getElementById('email-forgot-pw')

function onSendEmailSuccess () {
  window.alert('We sent you an email with instructions to reset your password. Make sure to check your Spam folder if you don\'t see it in your Inbox.')
}

function onSendEmailFail () {
  window.alert('Error submitting the password reset request. Please make sure you correctly entered your email address.')
}

async function sendEmail () {
  const email = emailEl.value

  if (email.length === 0) {
    window.alert('Please fill in your email address and password.')
    return
  }

  const user = { email }

  const request = await window.fetch('/api/user/reset-password-send-email', {
    method: 'POST',

    credentials: 'include',

    headers: {
      'Content-Type': 'application/json',
    },

    body: JSON.stringify({ user })
  })

  if (request.ok) {
    onSendEmailSuccess()
  } else {
    onSendEmailFail()
  }
}

document.getElementById('password-reset-button').addEventListener('click', sendEmail)
 })();
(function(){ if (!bodyEl.dataset.hasResetPassword) {
  return
}

const searchParams = new URLSearchParams(window.location.search)
const userId = searchParams.get('i')
const token = searchParams.get('t')
if (!userId || !token) {
  window.alert('Password reset link invalid. Please submit another request via the form on the "Members" page.')

  try {
    window.location.href = '/members/'
  } catch (err) {
    //
  }

  return
}

const passwordEl = document.getElementById('password')

function onResetSuccess () {
  window.alert('Password reset successful! You will now be redirected to the sign-in page.')

  try {
    window.location.href = '/members/'
  } catch (err) {
    //
  }
}

function onResetFail () {
  window.alert('Unable to reset password. Please submit another request via the form on the "Members" page.')

  try {
    window.location.href = '/members/'
  } catch (err) {
    //
  }
}

async function sendReset () {
  const password = passwordEl.value

  if (password.length < 8) {
    window.alert('Your new password must be at least 8 characters long.')
    return
  }

  const user = {
    id: userId,
    password,
  }

  const request = await window.fetch('/api/user/reset-password', {
    method: 'POST',

    headers: {
      'Content-Type': 'application/json',
    },

    body: JSON.stringify({ user, token })
  })

  if (request.ok) {
    onResetSuccess()
  } else {
    onResetFail()
  }
}

document.getElementById('password-reset-button').addEventListener('click', sendReset)
 })();
(function(){ if (!bodyEl.dataset.hasPayment) {
  return
}

const licenseType = new URLSearchParams(window.location.search).get('type')
if (!licenseType) {
  try {
    window.location.href = '/sign-up/'
  } catch (err) {
    window.alert('Invalid sign up link. Please click the "Sign Up" tab in the top-right to restart the process.')
  }

  return
}

function onCreateUserSuccess () {
  window.alert('Thank you for subscribing to Phillip Allen Shaw! Enjoy your unlimited downloads!')

  try {
    window.location.href = '/'
  } catch (err) {
    // Do nothing - we already told the user that sign-up succeeded.
  }
}

function onCreateUserFailed (reason) {
  if (reason === 'email-not-unique' || reason === 'customer-not-unique') {
    window.alert('You already have a subscription! Please sign in with your existing account.')

    try {
      window.location.href = '/members/'
    } catch (err) {
      // Do nothing
    }
  } else {
    window.alert('Error signing up. Please make sure the information you have entered is correct.')
  }
}

async function createUser () {
  const user = {
    email: document.getElementById('email').value,
    password: document.getElementById('password').value,
    firstName: document.getElementById('first-name').value,
    lastName: document.getElementById('last-name').value,
    address: document.getElementById('address').value,
    city: document.getElementById('city').value,
    state: document.getElementById('state').value,
    zipCode: document.getElementById('zip-code').value,
    ccNumber: document.getElementById('cc-number').value,
    ccExp: document.getElementById('cc-exp').value,
    ccCvc: document.getElementById('cc-cvc').value,
    licenseType
  }

  if (user.password.length < 8) {
    window.alert('Your password must be at least 8 characters long.')
    return
  }

  const request = await window.fetch('/api/user/create', {
    method: 'POST',

    credentials: 'include',

    headers: {
      'Content-Type': 'application/json',
    },

    body: JSON.stringify({ user })
  })

  if (request.ok) {
    onCreateUserSuccess()
  } else {
    let failReason = 'unknown'

    try {
      json = await request.json()

      if (typeof json.errors.error.errors === 'object') {
        const err = json.errors.error.errors

        if (typeof err.email === 'object' && err.email.message === 'is not unique') {
          failReason = 'email-not-unique'
        } else if (typeof err.stripeCustomerId === 'object' && err.stripeCustomerId.message === 'is not unique') {
          failReason = 'customer-not-unique'
        }
      }
    } catch (err) {
      // Fail reason already unknown.
    }

    onCreateUserFailed(failReason)
  }
}

document.getElementById('subs-button').addEventListener('click', createUser)
 })();
(function(){ if (!bodyEl.dataset.hasVerifyLicense) {
  return
}

const button = document.getElementById('subs-button')
const oldHref = button.href
const checkbox = document.getElementById('subs-check-input')
const signature = document.getElementById('signature-input')

function setButtonHref () {
  let agreed = checkbox.checked && (signature.value.length > 0)
  button.href = agreed ? oldHref : '#subs-check-container'
}

setButtonHref()

checkbox.addEventListener('change', setButtonHref)
signature.addEventListener('change', setButtonHref)

button.addEventListener('click', () => {
  if (!checkbox.checked) {
    window.alert('Please agree to the license terms.')
  } else if (signature.value.length <= 0) {
    window.alert('Please enter your name to sign the agreement.')
  }
})
 })();


